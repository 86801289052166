import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'store/types';

import { getNewMainPageSectionsData } from 'modules/NewMainPage/NewMainPage.selectors';
import { getSelectedCityId } from 'modules/Cities/Cities.selectors';

const mapState = (state: AppRootState) => ({
    cityId: getSelectedCityId(state),
    sectionsData: getNewMainPageSectionsData(state),
});

const mapDispatch = {};

export const NewMainPageConnector = connect(mapState, mapDispatch);
export type NewMainPageConnectProps = ConnectedProps<typeof NewMainPageConnector>;
