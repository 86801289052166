import React from 'react';
import { Space } from 'pyatka-ui';
import { SectionWrapper } from './components';
import MenuLinks from './components/MenuLinks';

const SectionMenuLinks: React.FC<any> = ({ departments = [], isLoading }) => (
    <>
        <SectionWrapper
            // @ts-ignore
            as="nav"
            className="section_departments"
            p={{ default: '18px', md: '12px 0 0 0' }}
            borderRadius={{ default: '0 0 24px 24px', md: '24px' }}
        >
            <MenuLinks links={departments} isLoading={isLoading} />
        </SectionWrapper>
        <Space py={['3px', '3px', '0px']} />
    </>
);

export default React.memo(SectionMenuLinks);
