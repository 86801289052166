import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Carousel, Display, Image } from 'pyatka-ui';
import { Helmet } from 'react-helmet-async';
import { AppLink } from 'components';
import { AppMetricEvents } from 'AppMetric';
import { GtmEvents } from 'metrics';
import placeholder from 'shared/assets/placeholder_1.png';
import { addCompressedSuffix } from 'modules/ProductsList/ProductsList.helpers';
import { SectionWrapper } from './components';

const SectionBanners: React.FC<any> = ({ banners, config, banner_size, isLoading, order }) => {
    const { group, showNavButton, dots_config, border_radius, carouselType, gap } = config;
    const { color, active_color, dots_position, show_dots } = dots_config;

    const firstBanner = banners?.length ? banners[0] : null;

    const bannerMetric = (banner: any = {}) => {
        AppMetricEvents.jmart_banner_click({
            order,
            position: banner?.position,
            source: 'main-page',
            keyword: banner?.title,
        });
        GtmEvents.selectPromotion(banner);
    };

    const prevId = React.useRef(9999);
    const handleShowSlide = (id: number) => {
        if (prevId.current !== id && !isLoading) {
            GtmEvents.viewPromotion(banners[`${id}`]);
            prevId.current = id;
        }
    };

    return (
        <>
            {firstBanner && banner_size === 'large' && (
                <Helmet>
                    <link rel="preload" href={firstBanner?.image_url} as="image" />
                    <link rel="preload" href={firstBanner?.app_image_url} as="image" />
                </Helmet>
            )}
            {/* Mobile */}
            <Display xxl={false} md={true}>
                <SectionWrapper
                    p={{ default: '16px 0 18px' }}
                    borderRadius={banner_size === 'small' ? '24px 24px 0 0' : '0'}
                    className="section_banners"
                >
                    <Carousel
                        gap={gap}
                        scrollAlign="center"
                        paddingHorizontal={20}
                        carouselType="magnetic"
                        slidesToShowGroup={group}
                        positionDots={dots_position}
                        autoScroll={banner_size === 'large' && banners.length > 1}
                        onShowSlide={handleShowSlide}
                    >
                        {banners.map((banner: any, idx: number) => (
                            <AppLink
                                to={banner?.url}
                                ariaLabel={banner?.title}
                                key={`${banner_size}-${idx}`}
                            >
                                <div onClick={() => bannerMetric(banner)}>
                                    <Image
                                        skeleton={isLoading}
                                        width="100%"
                                        appearance={
                                            banner_size === 'large' ? 'medium' : banner_size
                                        }
                                        src={banner?.app_image_url || banner?.image_url}
                                        preloadSrc={addCompressedSuffix(
                                            banner?.app_image_url || banner?.image_url || ''
                                        )}
                                        borderRadius={border_radius}
                                        fallbackImg={placeholder}
                                    />
                                </div>
                            </AppLink>
                        ))}
                    </Carousel>
                </SectionWrapper>
            </Display>
            {/* Desktop */}
            <Display md={false}>
                <StyledBox
                    banner_size={banner_size}
                    mx={banner_size === 'medium' ? '-9px' : '-5px'}
                    pb={{ default: banner_size === 'large' ? '8px' : '0', md: '35px' }}
                    pt={{ default: banner_size === 'large' ? '32px' : '0', md: '35px' }}
                >
                    <Carousel
                        carouselType={carouselType}
                        slidesToShowGroup={group}
                        showNavButtons={showNavButton}
                        showDots={show_dots}
                        positionDots={dots_position}
                        defaultDotsColor={color}
                        activeDotsColor={active_color}
                        autoScroll={banner_size === 'large' && banners.length > 1}
                        onShowSlide={handleShowSlide}
                    >
                        {banners.map((banner: any, idx: number) => (
                            <AppLink
                                to={banner?.url}
                                ariaLabel={banner?.title}
                                key={`${banner_size}-${idx}`}
                            >
                                <div onClick={() => bannerMetric(banner)}>
                                    <Image
                                        skeleton={isLoading}
                                        src={banner.image_url}
                                        preloadSrc={addCompressedSuffix(banner?.image_url)}
                                        appearance={banner_size}
                                        fallbackImg={placeholder}
                                        borderRadius={border_radius}
                                    />
                                </div>
                            </AppLink>
                        ))}
                    </Carousel>
                </StyledBox>
            </Display>
        </>
    );
};

const StyledBox = styled(Box)<{ banner_size: string }>`
    & button {
        opacity: 0.65;
        background-color: white;
        margin: 0px 7px;
    }

    ${(props) =>
        props.banner_size === 'medium'
            ? css`
                  & li {
                      border-left: 9px solid transparent;
                      border-right: 9px solid transparent;
                  }
              `
            : ''}
`;

export default React.memo(SectionBanners);
