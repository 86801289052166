import { banners_config } from 'modules/NewMainPage/config';

export const loadingSections = [
    {
        type: 'section_banners',
        data: {
            isLoading: true,
            banners: [
                {
                    url: '',
                    app_image_url: '',
                },
                {
                    url: '',
                    app_image_url: '',
                },
                {
                    url: '',
                    app_image_url: '',
                },
            ],
            config: banners_config.size_L,
            banner_size: 'large',
        },
    },
    {
        type: 'section_menu_links',
        data: {
            isLoading: true,
            departments: [
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: false,
                    url: '',
                },
            ],
        },
    },
    {
        type: 'section_dynamic',
        data: {
            isLoading: true,
            appearance: 'slide', //TODO
            sectionTitle: '',
            products: [
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
            ],
            buttonConfig: {
                url: '',
                buttonText: '',
            },
        },
    },
    {
        type: 'section_banners',
        data: {
            isLoading: true,
            banners: [
                {
                    url: '',
                    app_image_url: '',
                },
                {
                    url: '',
                    app_image_url: '',
                },
                {
                    url: '',
                    app_image_url: '',
                },
                {
                    url: '',
                    app_image_url: '',
                },
            ],
            config: banners_config.size_M,
            banner_size: 'medium',
        },
    },
    {
        type: 'section_categories',
        data: {
            isLoading: true,
            categories: [
                {
                    image: '',
                    title: '',
                    url: '',
                    main: '',
                },
                {
                    image: '',
                    title: '',
                    url: '',
                    main: '',
                },
                {
                    image: '',
                    title: '',
                    url: '',
                    main: '',
                },
                {
                    image: '',
                    title: '',
                    url: '',
                    main: '',
                },
                {
                    image: '',
                    title: '',
                    url: '',
                    main: '',
                },
                {
                    image: '',
                    title: '',
                    url: '',
                    main: '',
                },
            ],
        },
    },
    {
        type: 'company_section',
        data: {
            isLoading: true,
            sectionTitle: '',
            buttonConfig: {
                url: '',
                title: '',
            },
            markets: [
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
                {
                    image: '',
                    title: '',
                    isSupermarket: true,
                    url: '',
                },
            ],
        },
    },
    {
        type: 'section_products',
        data: {
            isLoading: true,
            appearance: 'slide', //TODO
            sectionTitle: '',
            products: [
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
                {
                    label: {},
                    price: '',
                    discountPrice: '',
                    productName: '',
                    image: '',
                    url: '',
                    isInCart: false,
                    addToCartHandler: {},
                    isLoading: true,
                },
            ],
            buttonConfig: {
                url: '',
                buttonText: '',
            },
        },
    },
];
