import React from 'react';
import styled from 'styled-components';
import { FlexBox, Image, Text, Box, Display, Carousel } from 'pyatka-ui';
import { AppLink } from 'components';
import { dispatchDepartmentMetric } from 'AppMetric/helpers';
import { useI18n } from 'hooks/useI18n';
import placeholder from 'shared/assets/placeholder_1.png';

interface MenuLinksProps {
    links: any[];
    isLoading: boolean;
}

const DIY_ID = 82;

const MenuLinks: React.FC<MenuLinksProps> = ({ links = [], isLoading = false }) => (
    <>
        {/* Mobile */}
        <Display xxl={false} md={true}>
            <MenuLinksMobile links={links} isLoading={isLoading} />
        </Display>

        {/* Tablet / Desktop */}
        <Display md={false}>
            <FlexBoxList
                mx="-1px"
                gap="16px"
                width="100%"
                overflow="auto"
                // justifyContent="space-between"
            >
                {links
                    .filter((link) => link.id !== DIY_ID)
                    .map((item: any) => (
                        <MenuLink key={item.title} {...item} isLoading={isLoading} />
                    ))}
            </FlexBoxList>
        </Display>
    </>
);

function chunkArrayInGroups(arr: any[], size: number) {
    const myArray = [];
    for (let i = 0; i < arr.length; i += size) {
        myArray.push(arr.slice(i, i + size));
    }
    return myArray;
}

const MenuLinksMobile: React.FC<{ links: any[]; isLoading: boolean }> = ({
    links,
    isLoading = false,
}) => {
    const slides = React.useMemo(() => {
        if (links.includes(DIY_ID)) {
            const arr = [{ id: -1 }, ...links];
            const chunked = chunkArrayInGroups(arr, 6);

            return chunked;
        }

        return chunkArrayInGroups(links, 6);
    }, [links]);

    return (
        <StyledCarousel>
            <Carousel
                showDots
                scrollAlign="start"
                carouselType="magnetic"
                activeDotsColor="block.brand_variant"
                defaultDotsColor="block.secondary_variant"
                slidesToShowGroup={{ mobile: 1, tablet: 1, desktop: 1 }}
            >
                {slides.map((slide, i) => (
                    <IconGrid key={i}>
                        {slide
                            .filter((el) => el.id !== -1)
                            .map((item) => (
                                <AppLink
                                    to={item.url}
                                    key={item.id}
                                    onClickProduct={() => {
                                        dispatchDepartmentMetric(
                                            Number(item.id),
                                            item.title,
                                            false
                                        );
                                    }}
                                    style={{
                                        gridColumn: item.id === DIY_ID ? 'span 2' : undefined,
                                    }}
                                >
                                    <FlexBox
                                        pt="0px"
                                        height="100%"
                                        padding="12px"
                                        position="relative"
                                        alignItems="center"
                                        borderRadius="24px"
                                        flexDirection="column"
                                        bg={item.id === DIY_ID ? '#FFF0D3' : 'block.secondary'}
                                    >
                                        <Box position="relative" top="-24px" mb="-23px">
                                            <Image
                                                src={item.image}
                                                appearance="100%"
                                                effect="fadeIn"
                                                width="83px"
                                                new_image
                                            />
                                        </Box>
                                        <FlexBox flex={1} alignItems="center">
                                            <Text
                                                fontSize="12px"
                                                fontWeight={600}
                                                color="text.main"
                                                textAlign="center"
                                                lineHeight="15.36px"
                                                lineClamp={2}
                                                style={{ whiteSpace: 'break-spaces' }}
                                            >
                                                {item.title}
                                            </Text>
                                        </FlexBox>
                                    </FlexBox>
                                </AppLink>
                            ))}
                        {/* <pre>{JSON.stringify(slide, null, 2)}</pre> */}
                    </IconGrid>
                ))}
            </Carousel>
        </StyledCarousel>
    );
};

const MenuLink: React.FC<any> = ({ id, url, image, title = '', isLoading = false }) => {
    const { $t } = useI18n();

    // Image Props
    const imageSize = { default: '46px', md: '48px' };
    const imageRadius = { default: '12px', md: '15px' };

    return (
        <Box title={$t(title)}>
            <AppLink to={url}>
                <FlexBox
                    flexDirection={{ default: 'column', md: 'row' }}
                    onClick={() => dispatchDepartmentMetric(id, title)}
                    width={{ default: '100%' }}
                    justifyContent="center"
                    borderRadius="999rem"
                    bg="block.secondary"
                    alignItems="center"
                    gap="6px"
                    pr="18px"
                    pl="12px"
                >
                    <Box>
                        <Image
                            src={image}
                            width={imageSize}
                            height={imageSize}
                            skeleton={isLoading}
                            borderRadius={imageRadius}
                            fallbackImg={placeholder}
                        />
                    </Box>
                    <Text
                        color="text.main"
                        skeleton={isLoading}
                        skeletonProps={skeletonProps}
                        style={{ whiteSpace: 'initial' }}
                        textAlign={{ default: 'center', md: 'left' }}
                        fontWeight={{ md: 500 }}
                        fontSize={{ md: '18px' }}
                        lineHeight="23.4px"
                    >
                        {$t(title)}
                    </Text>
                </FlexBox>
            </AppLink>
        </Box>
    );
};

const IconGrid = styled.div`
    display: grid;
    grid-auto-rows: min-content;
    padding-top: 16px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 8px;
    grid-row-gap: 32px;
`;

const StyledCarousel = styled.div`
    & div[data-testid='dots'] {
        margin: 12px 0 2px;
        & div {
            width: 6px;
            height: 6px;
            margin: 0 4px;
            aspect-ratio: 1/1;
        }
    }

    & li {
        border: 1px solid transparent;
    }
`;

const FlexBoxList = styled(FlexBox)`
    &::-webkit-scrollbar {
        display: none;
    }
`;

const skeletonProps = {
    height: '1em',
    borderRadius: 12,
    marginTop: { default: '9px', md: 0 },
    width: { default: '100%', md: '8em' },
    marginLeft: { default: 0, md: '15px' },
};

export default React.memo(MenuLinks);
