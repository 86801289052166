import React from 'react';
import { Container, Display, usePyatka } from 'pyatka-ui';
import { useWindowVirtualizer } from '@tanstack/react-virtual';
import { loadingSections } from 'pages/NewMainPage/const';
import { AppMetricEvents } from 'AppMetric';
import { PromotionModal } from 'components';
import { NewMainPageLayoutProps } from './types';
import Section from './NewMainPage.Section';
import MobileFooter from './NewMainPage.MobileFooter';

const NewMainPageLayout: React.FC<NewMainPageLayoutProps> = ({ sectionsData = [], isLoading }) => {
    const parentRef = React.useRef<HTMLDivElement>(null);
    const parentOffsetRef = React.useRef(0);

    React.useLayoutEffect(() => {
        parentOffsetRef.current = parentRef.current?.offsetTop || 0;
    }, []);

    const virtualizer = useWindowVirtualizer({
        count: ((sectionsData as any[]) || []).length,
        estimateSize: () => 45,
        scrollMargin: parentOffsetRef.current,
        overscan: 2,
    });

    const items = virtualizer.getVirtualItems();

    const { scales } = usePyatka();

    const isLargeScreen = scales?.name === 'xxl';
    const containerPadding = isLargeScreen ? '0 15px' : '';

    React.useEffect(() => {
        AppMetricEvents.jmart_main_view();
    }, []);

    return (
        <React.Fragment>
            <PromotionModal isMobile={scales?.isMobile} />

            <Container ref={parentRef} style={{ padding: containerPadding }}>
                {!isLoading && (
                    <div
                        style={{
                            height: virtualizer.getTotalSize(),
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                transform: `translateY(${
                                    items[0]?.start - virtualizer.options.scrollMargin
                                }px)`,
                            }}
                        >
                            {items.map((virtualRow) => {
                                const { type, data } = sectionsData[virtualRow.index];

                                return (
                                    <div
                                        key={virtualRow.key}
                                        data-index={virtualRow.index}
                                        ref={virtualizer.measureElement}
                                    >
                                        <Section
                                            type={type}
                                            data={data}
                                            order={virtualRow?.index}
                                            isLast={virtualRow?.index === sectionsData.length - 1}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
                {isLoading &&
                    loadingSections.map((section: any, idx: number) => (
                        <React.Fragment key={idx}>
                            <Section {...section} />
                        </React.Fragment>
                    ))}
                <Display md={true} lg={false} xl={false} xxl={false}>
                    <MobileFooter />
                </Display>
            </Container>
        </React.Fragment>
    );
};

export default React.memo(NewMainPageLayout);
