import React from 'react';
import { Display, Space } from 'pyatka-ui';
import { ProductCardList } from 'components';
import { ProductCardListProps } from 'components/ProductCardList/types';
import { GtmEvents } from 'metrics';
import { AppMetricEvents } from 'AppMetric';
import { SectionWrapper } from './components';

interface SectionProductsProps extends ProductCardListProps {
    items_per_row?: number;
    labels?: { text?: string; type?: string };
    isLast?: boolean;
}

const SectionProducts: React.FC<SectionProductsProps> = ({
    products = [],
    sectionTitle,
    buttonConfig,
    appearance,
    isLoading,
    items_per_row,
    labels,
    isLast = false,
}) => {
    React.useEffect(() => {
        if (sectionTitle && products.length) {
            GtmEvents.viewItemList(products, sectionTitle);
        }
    }, [sectionTitle, products]);

    const productClickMetric = (data: any) => {
        AppMetricEvents.product_click({
            source: 'main-page',
            data: {
                ...data,
                sectionTitle,
            },
        });
    };

    const metric = {
        product_click: productClickMetric,
    };

    const productListProps: ProductCardListProps = {
        products,
        sectionTitle,
        buttonConfig,
        isLoading,
        metric,
        labels,
        cardLayout: 'main',
        desktopCardLayout: 'main',
        source: 'main_page',
    };

    return (
        <React.Fragment>
            {/* Tablet, Desktop */}
            <Display sm={false} lg={true}>
                <Space height="35px" />
                <ProductCardList
                    gap="20px"
                    appearance="slide"
                    buttonColor="block.main"
                    wrapperStyle={{ mr: { default: '-8px' }, mt: '30px', px: '8px' }}
                    count={{ default: 4.2, lg: items_per_row || 6 }}
                    {...productListProps}
                />
                <Space height="35px" />
            </Display>
            {/* Mobile */}
            <Display sm={true} xxl={false}>
                <SectionWrapper
                    p={{ default: '12px 18px', md: '18px 9px' }}
                    borderRadius={isLast ? '0 0 24px 24px' : '0px'}
                >
                    {appearance === 'slide' ? (
                        <ProductCardList
                            minWidth={120}
                            appearance="slide"
                            gap={{ default: '12px' }}
                            buttonColor="block.secondary"
                            wrapperStyle={{ mx: { default: '-18px' } }}
                            sliderStyle={{ px: { default: '18px' } }}
                            count={{ default: 2.6, lg: items_per_row || 6 }}
                            {...productListProps}
                        />
                    ) : (
                        <ProductCardList count={2} appearance="grid" {...productListProps} />
                    )}
                    {isLast && <Space height="18px" />}
                </SectionWrapper>
            </Display>
        </React.Fragment>
    );
};

export default React.memo(SectionProducts);
