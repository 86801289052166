import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Col,
    Container,
    Display,
    FlexBox,
    Icon,
    Image,
    Modal,
    Row,
    Space,
    Text,
    usePyatka,
} from 'pyatka-ui';
import { useI18n } from 'hooks/useI18n';
import { isNative } from 'utils/platform';
import { nativeCall } from 'hybrid';
import backgroundImg from '../../assets/partner-desktop-image.png';
import { contacts, desktopProps, formatPhone, IContact, isValidPhoneNumber } from './utils';

export const ContactsPageLayout: React.FC = () => {
    const { $t } = useI18n();

    return (
        <Container>
            <Row>
                <Col
                    xs={12}
                    sm={12}
                    md={5}
                    lg={4}
                    as={Box}
                    zIndex={10}
                    padding={{
                        default: '0px !important',
                        md: '40px 16px !important',
                    }}
                >
                    <Display {...desktopProps}>
                        <Text
                            // @ts-ignore
                            as="h1"
                            mb="15px"
                            mx="40px"
                            fontWeight="bold"
                            fontSize={{ default: 7, lg: '40px' }}
                        >
                            {$t('contacts')}
                        </Text>
                    </Display>
                    <FlexBox
                        p="40px"
                        pt="30px"
                        bg="block.main"
                        flexDirection="column"
                        mx={{ default: '-18px', md: 0 }}
                        borderRadius={{ default: 0, md: 30 }}
                        maxWidth={{ default: 'auto', md: '407px' }}
                    >
                        <Text fontSize={{ md: '1em', lg: '18px' }} lineHeight="24px" mb="18px">
                            {$t('working_hours_call_center')}
                        </Text>
                        <WorkTime days={$t('mn-fr')} time="08:00-24:00" />
                        <WorkTime days={$t('st-sn')} time="08:00-22:00" />
                        <Space height="10px" />
                        {contacts.map(
                            (item) =>
                                item.value !== $t(item.value) && (
                                    <ContactItem item={item} key={item.link + item.value} />
                                )
                        )}
                    </FlexBox>
                </Col>
                <Col
                    as={Box}
                    position={{
                        default: 'absolute !important',
                        lg: 'relative !important',
                    }}
                    zIndex={-10}
                    left={0}
                    right={0}
                >
                    <Display {...desktopProps}>
                        <Image src={backgroundImg} backgroundSize="cover" />
                    </Display>
                </Col>
            </Row>
        </Container>
    );
};

const WorkTime: React.FC<{
    days: string;
    time: string;
}> = ({ days, time }) => (
    <FlexBox gap="18px" py="3px" alignItems="center">
        <FlexBox width="28px" alignItems="center" justifyContent="center">
            <Box width="10px" height="10px" borderRadius="999rem" bg="status.success" />
        </FlexBox>
        <FlexBox gap="8px" alignItems="center" width="100%" lineHeight="22px">
            <Text color="text.secondary">{days}</Text>
            <Text>{time}</Text>
        </FlexBox>
    </FlexBox>
);

const ContactItem: React.FC<{
    item: IContact;
}> = ({ item }) => {
    const { $t } = useI18n();
    const { notClickable = false } = item;
    const [isOpen, setIsOpen] = useState(false);

    const callWhatsApp = () => {
        if (!isNative()) {
            setIsOpen(true);
            return;
        }

        nativeCall({
            type: 'whatsapp',
            payload: {},
        });
    };

    const onPhoneClick = () => {
        if (isNative()) {
            nativeCall({
                type: 'call',
                payload: {
                    phone: formatPhone($t(item.value)),
                },
            });
        }
    };

    const isWhatsApp = item.icon === 'whatsapp_circle';

    const onClick = () => {
        if (notClickable) {
            return;
        }
        if (isWhatsApp) {
            if (!isValidPhoneNumber(formatPhone($t(item.value)))) {
                return;
            }
            return callWhatsApp();
        } else if (item.icon === 'telephone') {
            return onPhoneClick();
        }
    };

    const href = useMemo(() => {
        switch (item.icon) {
            case 'whatsapp_circle':
                return undefined;
            case 'telephone':
                return `tel:${formatPhone($t(item.value))}`;
            default:
                return `${item.link + $t(item.value)}`;
        }
    }, [$t, item.icon, item.link, item.value]);

    return (
        <FlexBox gap="18px" alignItems="center">
            <Icon icon={item.icon} width="28px" />
            <FlexBox
                py="12px"
                gap="18px"
                width="100%"
                cursor={!notClickable ? 'pointer' : 'auto'}
                alignItems="center"
                borderTopWidth="1px"
                borderTopStyle="solid"
                borderTopColor="block.secondary"
                justifyContent="space-between"
                // action
                href={!notClickable ? href : undefined}
                onClick={onClick}
                as={isWhatsApp ? 'div' : 'a'}
            >
                <FlexBox flexDirection="column">
                    <Text color="text.secondary" lineHeight="18px" fontSize="14px">
                        {$t(item.label)}
                    </Text>
                    <Text color="text.main" fontSize={{ md: '1em', lg: '18px' }} lineHeight="24px">
                        {$t(item.value)}
                    </Text>
                </FlexBox>
                {!notClickable && isValidPhoneNumber(formatPhone($t(item.value))) && (
                    <Icon icon="arrow-right" />
                )}
            </FlexBox>
            <WhatsAppModal isOpen={isOpen} item={item} onClose={() => setIsOpen(false)} />
        </FlexBox>
    );
};

export const WhatsAppModal: React.FC<{
    item: IContact;
    isOpen: boolean;
    onClose: any;
}> = ({ item, isOpen, onClose }) => {
    const { $t } = useI18n();
    const [isChecked, setIsChecked] = useState(true);
    const {
        scales: { isMobile },
    } = usePyatka();

    const url = useMemo(() => {
        const link = `${item.link}${formatPhone($t(item.value))}`;
        if (isChecked) {
            return `${link}?text=${__BROWSER__ ? window.navigator.userAgent : 'Web Browser'}`;
        }
        return item.link;
    }, [$t, isChecked, item.link, item.value]);
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            positionMode="mobile"
            width={isMobile ? '100%' : '50%'}
            padding={'25px'}
        >
            <FlexBox justifyContent="space-between" alignItems="flex-start" gap="20px">
                <Checkbox
                    checked={isChecked}
                    onChange={() => setIsChecked((v) => !v)}
                    style={{ flex: 'none' }}
                    enterKeyHint={undefined}
                    css={undefined}
                    crossOrigin=""
                />
                <FlexBox flexDirection="column" gap="16px">
                    <Text fontWeight="700" fontSize="20px" lineHeight="26px">
                        {$t('send_my_data_from_jmart')}
                    </Text>
                    <Text lineHeight="22px" color="text.secondary">
                        {$t('necessary_speed_up_identification')}
                    </Text>
                </FlexBox>
                <div>
                    <FlexBox
                        bg="block.secondary"
                        width="32px"
                        height="32px"
                        cursor="pointer"
                        borderRadius="50%"
                        alignItems="center"
                        justifyContent="center"
                        onClick={onClose}
                    >
                        <Icon icon="close" fill="text.placeholder" />
                    </FlexBox>
                </div>
            </FlexBox>
            <Button
                // @ts-ignore
                as="a"
                href={url}
                target="_blank"
                onClick={onClose}
                rel="noopener noreferrer"
                styleType="contained"
                borderRadius="22px"
                variant="brand"
                py="16px"
                mt="28px"
            >
                {$t('go_whatsapp')}
            </Button>
        </Modal>
    );
};
