import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { useLoader } from 'contexts/useLoader';
import { Loader } from 'plugins/loader';
import NewMainPageLayout from './NewMainPage.Layout';
import { NewMainPageProps } from './types';

export const NewMainPageContainer: React.FC<NewMainPageProps> = ({
    route,
    sectionsData,
    cityId,
}) => {
    const dispatch = useDispatch();
    const { isLoading } = useLoader();
    const isMainPageLoading = isLoading(Loader.main.indicator);

    React.useEffect(() => {
        if (route?.fetchPageData) {
            route.fetchPageData({ dispatch });
        }
    }, [cityId]);

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    Jmart - маркетплейс товаров и услуг в рассрочку и кредит по всему Казахстану
                </title>
                <meta
                    name="description"
                    content="Покупка бытовой техники и электроники в кредит по лучшим ценам в Казахстане.
                    Большой выбор товаров и услуг, удобная процедура оформления — Jmart"
                />
                <meta
                    property="og:description"
                    content="Покупка бытовой техники и электроники в кредит по лучшим ценам в Казахстане.
                    Большой выбор товаров и услуг, удобная процедура оформления — Jmart"
                />
                <link rel="canonical" href="https://jmart.kz" />
            </Helmet>
            <NewMainPageLayout sectionsData={sectionsData} isLoading={isMainPageLoading} />
        </React.Fragment>
    );
};
