import React from 'react';
import { Box } from 'pyatka-ui';
import { BoxProps } from 'pyatka-ui/dist/components-shared/Box/types';

interface SectionWrapperProps extends Omit<BoxProps, 'color'> {
    children?: React.ReactNode;
}

export const SectionWrapper: React.FC<SectionWrapperProps> = ({
    children,
    borderRadius = '0px',
    p = { default: '18px', md: '18px 9px' },
    ...rest
}) => (
    <Box
        p={p}
        bg="white"
        mx={{ default: '-18px', md: '0px' }}
        borderRadius={borderRadius}
        {...rest}
    >
        {children}
    </Box>
);
