import React, { useState } from 'react';
import { Box, FlexBox, Icon, Text } from 'pyatka-ui';
import { AppLink } from 'components';
import { nativeCall } from 'hybrid/index';
import { isNative, triggerQrModal } from 'utils/platform';
import { useI18n } from 'hooks/useI18n';
import { AppMetricEvents } from 'AppMetric';
import { contacts } from 'modules/Articles/const';
import { StaticPage } from 'modules/Articles/types';
import { WhatsAppModal } from 'pages/ContactsPage/ContactsPage.Layout';
import { contacts as wpcontacts } from 'pages/ContactsPage/utils';

const MobileFooter: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const callWhatsApp = () => {
        if (!isNative()) {
            setIsOpen(true);
            // triggerQrModal('/');
            return;
        }

        nativeCall({
            type: 'whatsapp',
            payload: {},
        });
    };
    const item_wpp = wpcontacts.find((item) => item.icon === 'whatsapp_circle');
    return (
        <footer>
            <FlexBox
                px="18px"
                pt="22px"
                pb="32px"
                mt="6px"
                mx="-18px"
                mb="-4px"
                gap="18px"
                flexDirection="column"
                borderRadius="24px 24px 0 0"
                backgroundColor="block.main"
            >
                {contacts.map((item) => (
                    <React.Fragment key={item.id}>
                        {item.id === 'whatsapp' ? (
                            <Box onClick={callWhatsApp}>
                                <FooterLink item={item} />
                            </Box>
                        ) : (
                            <AppLink to={item.link}>
                                <FooterLink item={item} />
                            </AppLink>
                        )}
                    </React.Fragment>
                ))}
                <WhatsAppModal isOpen={isOpen} item={item_wpp} onClose={() => setIsOpen(false)} />
            </FlexBox>
        </footer>
    );
};

interface IFooterLink {
    item: StaticPage;
}

const FooterLink: React.FC<IFooterLink> = ({ item }) => {
    const { $t } = useI18n();

    const handleMetrics = () => {
        if (item.id === 'partner') {
            AppMetricEvents.jmart_partner_start();
            return;
        }

        if (item.id === 'qrlite') {
            AppMetricEvents.jmart_qrlite_start();
            return;
        }
    };

    return (
        <FlexBox alignItems="center" gap="16px" onClick={handleMetrics}>
            <Icon icon={icons[item.id]} fill={'#AAADB2'} />
            <Text color="text.main"> {$t(item.title)} </Text>
        </FlexBox>
    );
};

const icons: Record<string, string> = {
    payment_and_delivery: 'deliveryFooter',
    vozvrat: 'returnFooter',
    kontakty: 'contactsFooter',
    oferta: 'offerFooter',
    dogovor: 'treatyFooter',
    whatsapp: 'messageFooter',
    partner: 'partner_mobile',
    qrlite: 'qrlite',
};

export default React.memo(MobileFooter);
