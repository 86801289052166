import React from 'react';
import { Space } from 'pyatka-ui';
import { DepartmentBoxList, SectionTitle } from 'components';
import { SectionWrapper } from './components';

const SectionMarkets: React.FC<any> = ({ markets, sectionTitle, buttonConfig, isLoading }) => {
    const { url } = buttonConfig;

    const sectionTitleProps = {
        sectionText: sectionTitle,
        url,
    };

    return (
        <SectionWrapper>
            <SectionTitle {...sectionTitleProps} buttonColor="block.secondary" />
            <Space marginTop={{ default: '12px', md: '30px' }} />
            <DepartmentBoxList isLoading={isLoading} isSupermarket departments={markets} />
        </SectionWrapper>
    );
};

export default React.memo(SectionMarkets);
