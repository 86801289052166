import React from 'react';

import { ISection } from 'modules/NewMainPage/types';
import { CategoryBoxList } from 'components';
import SectionBanners from './NewMainPage.SectionBanners';
import SectionProducts from './NewMainPage.SectionProducts';
import SectionMenuLinks from './NewMainPage.SectionMenuLinks';
import SectionMarkets from './NewMainPage.SectionMarkets';

const sections: Record<ISection['type'] | any, any> = {
    section_categories: CategoryBoxList,
    section_banners: SectionBanners,
    section_supermarket_banner: SectionBanners,
    section_products: SectionProducts,
    section_dynamic: SectionProducts,
    section_menu_links: SectionMenuLinks,
    company_section: SectionMarkets,
};

interface SectionProps extends ISection {
    isLast: boolean;
}

const Section: React.FC<SectionProps> = ({ type, data, order, isLast = false }) => {
    const Section = sections[`${type}`];

    if (Section !== undefined) {
        return <Section order={order} {...data} isLast={isLast} />;
    }

    return null;
};

export default React.memo(Section);
