export const desktopProps = {
    xs: false,
    sm: false,
    md: false,
    lg: true,
    xl: true,
    xxl: true,
};

export interface IContact {
    icon: string;
    link: string;
    value: string;
    label: string;
    notClickable?: boolean;
}

export const contacts: IContact[] = [
    {
        icon: 'mail',
        link: 'mailto:',
        value: 'jmart_email',
        label: 'write_to_email',
    },
    {
        icon: 'telephone',
        link: '',
        value: 'call_center_phone1',
        label: 'call',
        notClickable: true,
    },
    {
        icon: 'telephone',
        link: '',
        value: 'call_center_phone2',
        label: 'call',
    },
    {
        icon: 'whatsapp_circle',
        link: 'https://wa.me/',
        value: 'call_center_phone_wpp',
        label: 'write_to_whatsapp',
    },
];
export const formatPhone = (phone: string) => {
    return phone.replace(/[() ]/g, '');
};
export const isValidPhoneNumber = (url: string) => {
    // Regex pattern for matching international phone numbers
    const phoneRegex = /\+\d{1,3}\d{1,12}/;

    // Test if the URL contains a phone number
    return phoneRegex.test(url);
};
